import { SymfonyFields } from "@src/api/interfaces/Symfony";
import { ApiResponse } from "@src/api/types/ApiResponse";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { JobEditFormInputs } from "@src/components/forms/jobs/JobEditForm";
import { Customer } from "@src/types/DashboardTypes";
import argsBuilder from "@src/utils/args-builder";
import { MINUTE } from "@src/utils/cache";
import { deleteRelatedItemsFromCacheStorage } from "@src/utils/cache-storage-api";
import { get, post, put } from "@src/utils/request";
import React from "react";

export const JobsContext = React.createContext<Job[]>([]);

export const fetchJobs = async (
    sort?: string | undefined,
    page?: number,
    pageSize?: number,
    freeText?: string,
    customer?: CinnamonID,
    archived?: number | null,
    runtimeStart?: string,
    runtimeEnd?: string,
): Promise<ApiResponse<Job>> => {
    const args = argsBuilder({
        archived,
        customer,
        freeText,
        page,
        pageSize,
        runtimeEnd,
        runtimeStart,
        sort,
    });

    return await get<ApiResponse<Job>>(`/internalApi/jobs${args}`, { cache: true, ttl: 5 * MINUTE });
};

export const createJob = async (body: JobEditFormInputs): Promise<void> => {
    await post("/internalApi/jobs", { body });
    await burstJobCaches();
};

export const updateJob = async (jobId: CinnamonID, body: JobEditFormInputs): Promise<void> => {
    await put(`/internalApi/jobs/${jobId}`, { body });
    await burstJobCaches();
};

export const duplicateJob = async (jobId: CinnamonID): Promise<Job> => {
    const duplicatedJob = await post<Job>(`/internalApi/jobs/${jobId}/duplicate`);
    await burstJobCaches();
    return duplicatedJob;
};

export const burstJobCaches = async () => deleteRelatedItemsFromCacheStorage("/internalApi/jobs");

export interface Job extends SymfonyFields {
    account: string | null;
    actualCost: string;
    archived: boolean;
    budget: number;
    contact: string;
    currency: string;
    customer: Customer;
    estimatedCosts: string;
    expenseAccount: string | null;
    externalId: CinnamonID | null;
    id: CinnamonID;
    name: string;
    numId: CinnamonID;
    notes: string;
    number: string;
    runtimeEnd: string;
    runtimeStart: string;
    suppliers: Customer[];
}

/**
 * This interface is used to extend the Job interface with fields only available when specific features are enabled.
 */
export interface JobFeatureFields extends Job {
    aprimoSync: boolean;
}
