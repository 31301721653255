import { ExpandCircleDown } from "@mui/icons-material";
import { Box, Drawer, IconButton, Stack, Toolbar, Tooltip, Typography, useTheme } from "@mui/material";
import { closedDrawerWidth, drawerWidth } from "@src/components/theme/theme";
import { setNavigationOpenStatus } from "@src/features/navigation-bar/navigation-bar-slice";
import { useAppDispatch } from "@src/hooks/redux";
import { redirects, routes } from "@src/routes";
import { MINUTE } from "@src/utils/cache";
import { deleteRelatedItemsFromCacheStorage } from "@src/utils/cache-storage-api";
import { get } from "@src/utils/request";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { createMenuStructure, MenuEntry } from "./menu";

interface NavigationProps {
    navigationOpen: boolean;
}

const Navigation: React.FC<NavigationProps> = ({ navigationOpen }) => {
    const location = useLocation();
    const [menu, setMenu] = useState<MenuEntry[]>([]);
    const [menuUpdate, triggerMenuUpdate] = useState(0);
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    // TODO: move this somewhere else...
    useEffect(() => {
        const fetchMenu = async () => {
            let menuData = menu;

            if (menuData.length === 0) {
                menuData = await get<MenuEntry[]>("/internalApi/navigation", { cache: true, ttl: 15 * MINUTE });

                if (!Array.isArray(menuData)) {
                    await deleteRelatedItemsFromCacheStorage("/internalApi/navigation");
                    setTimeout(() => triggerMenuUpdate(Date.now()), 200);
                    return;
                }
                setMenu(menuData);
            }
        };
        fetchMenu();
    }, [menu, menuUpdate]);

    const isSupported = (path: string): boolean => {
        const supportedPaths = [...Object.keys(routes), ...Object.keys(redirects)];
        return supportedPaths.indexOf(path) > -1;
    };

    const updateMenuEntry = (menuEntry: MenuEntry) => {
        if (menuEntry.link) {
            // TODO
            // The following workaround has to be inserted because otherwise multiple buttons would be highlighted.
            // The reason for that: Three pathnames contain the string "statistics" and will therefore also be set to the "selected" state.
            // After shutting down the old frontend it is recommended to change the route "/statistics" to "statistics/overview" both in the frond-
            // and the backend. This will enforce unique pathnames and this workaround can be removed.
            if (menuEntry.link == "/statistics") {
                menuEntry.selected =
                    location.pathname.includes("/statistics") &&
                    !location.pathname.includes("/statistics/annual_planing") &&
                    !location.pathname.includes("/statistics/unfinished_services");
            } else {
                menuEntry.selected = location.pathname.includes(menuEntry.link);
            }
        }

        menuEntry.unsupported = Boolean(menuEntry.link && !isSupported(menuEntry.link));

        // TODO: This trans should be changed in Backend, when the react frontend is completely implemented
        if (menuEntry.name === "Artikelverwaltung") {
            menuEntry.name = i18n.t("component.navigation.templates") as string;
        }

        if (menuEntry.name === "Produktkategorien") {
            menuEntry.name = i18n.t("pages.productcategories.title") as string;
        }
    };

    menu.forEach((menuEntry: MenuEntry) => {
        updateMenuEntry(menuEntry);
        menuEntry.items?.forEach(updateMenuEntry);
    });

    const toggleMenu = () => {
        dispatch(setNavigationOpenStatus(!navigationOpen));
    };

    const variableDrawerHeight = CINNAMON_IS_PREVIEW || CINNAMON_IS_STAGE ? "calc(100% - 44px)" : "calc(100% - 20px)";

    return (
        <Drawer
            open={navigationOpen}
            sx={{
                ["& .MuiDrawer-paper"]: {
                    boxSizing: "border-box",
                    height: variableDrawerHeight,
                    mt: CINNAMON_IS_PREVIEW || CINNAMON_IS_STAGE ? "20px" : 0,
                    width: navigationOpen ? drawerWidth : closedDrawerWidth,
                },
                flexShrink: 0,
                width: navigationOpen ? drawerWidth : closedDrawerWidth,
            }}
            variant="permanent"
        >
            <Toolbar />
            <Box
                sx={{
                    ["* .MuiTouchRipple-root"]: {
                        display: "none",
                    },

                    backgroundColor: theme.palette.highlightGrey.main,
                    justifyContent: navigationOpen ? "left" : "center",
                    pt: 1,
                }}
            >
                <Tooltip
                    placement="right"
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: "offset",
                                    options: {
                                        offset: [0, -20],
                                    },
                                },
                            ],
                        },
                    }}
                    title={!navigationOpen ? t("component.layout.open-navigation") : ""}
                >
                    <IconButton
                        aria-label="open drawer"
                        color="inherit"
                        edge="start"
                        onClick={toggleMenu}
                        sx={{
                            ":hover": {
                                backgroundColor: "transparent",
                            },
                            justifyContent: navigationOpen ? "initial" : "center",
                            pl: navigationOpen ? "29px" : "29px",
                        }}
                    >
                        <Stack
                            direction="row"
                            sx={{
                                alignItems: "center",
                            }}
                        >
                            {navigationOpen ? (
                                <>
                                    <ExpandCircleDown
                                        sx={{
                                            color: theme.palette.primary.main,
                                            mr: "12px",
                                            rotate: "90deg",
                                        }}
                                    />
                                    <Typography>{t("component.layout.close-navigation")}</Typography>
                                </>
                            ) : (
                                <ExpandCircleDown
                                    sx={{
                                        color: theme.palette.primary.main,
                                        mr: "12px",
                                        rotate: "-90deg",
                                    }}
                                />
                            )}
                        </Stack>
                    </IconButton>
                </Tooltip>
            </Box>
            <Box sx={{ overflow: "auto" }}>{createMenuStructure(menu, navigationOpen, theme)}</Box>
        </Drawer>
    );
};
export default React.memo(Navigation);
