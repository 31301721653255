import { disableMaintenance, enableMaintenance } from "@src/features/maintanance/maintenance-slice";
import { store } from "@src/store";
import { getCacheStorage, updateCacheStorage } from "@src/utils/cache-storage-api";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { MINUTE } from "./cache";
import log from "./logger";

export enum RequestMethod {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
}

export type ResponseType = "arraybuffer" | "blob" | "document" | "json" | "text" | "stream";

export interface RequestOptions {
    headers?: {
        [name: string]: string;
    };
    body?: string | number | object | null;
    cache?: boolean;
    ttl?: number;
    responseType?: ResponseType;
    params?: object | null;

    /**
     * Controls if the 403 should be bubbled/returned up to the caller. Will be returned as a promise rejection.
     */
    bubble403?: boolean;
}

const cacheTTL = 10 * MINUTE;

const normalizeAxiosHeadersForResponse = (axiosResponse: AxiosResponse): [string, string][] =>
    Object.keys(axiosResponse.headers).map(key => [key, axiosResponse.headers[key]]);

export const request = async <Type>(
    method: RequestMethod,
    url: string,
    requestOptions: RequestOptions = {},
): Promise<Type> => {
    url = CINNAMON_BACKEND_PREFIX + url;
    const state = store.getState();

    const impersonatedUser = state.impersonation.username;

    if (requestOptions.cache && !impersonatedUser) {
        log.debug(`Get Data: " ${method} ${url} " from Cache Storage`);
        const data = await getCacheStorage<Type>(url);

        if (data) {
            log.debug(`Data found in cache: ${url}`);
            return data;
        }
    }

    const options: AxiosRequestConfig = {
        headers: {
            "Content-Type": "application/json",
        },
        // maxRedirects set to prevent axios from redirecting to login page. This might happen if one of the requests
        // is issued before we have a valid authentication.
        maxRedirects: 0,
        method: method.toLowerCase(),
        url,
    };

    if (requestOptions.responseType) {
        options.responseType = requestOptions.responseType;
    }

    if (requestOptions.body) {
        options.data = JSON.stringify(requestOptions.body);
    }

    if (requestOptions.params) {
        options.params = requestOptions.params;
    }

    try {
        log.debug(`Request ${method} ${url}`);
        const res = await axios.request(options);

        if (requestOptions.cache && !impersonatedUser) {
            log.debug(`Store Data: " ${method} ${url} " in Cache Storage`);
            await updateCacheStorage(
                url,
                new Response(JSON.stringify(res.data), {
                    headers: normalizeAxiosHeadersForResponse(res),
                    status: res.status,
                    statusText: res.statusText,
                }),
                requestOptions.ttl || cacheTTL,
            );
        }

        // No 503 so we are out of maintenance mode
        store.dispatch(disableMaintenance());

        return Promise.resolve(res.data);
    } catch (err) {
        const errMessage = err instanceof Error ? err.message : err;
        const response = err instanceof AxiosError ? (err.response?.data ?? err.response) : undefined;

        log.error(`Error while fetching: ${method} ${url}: ${errMessage}`, { errMessage, response });

        if (err instanceof AxiosError) {
            if (err.request.status === 401) {
                log.debug("401 Unauthorized, redirecting user to /login");
                location.href = "/login";
            }

            if (err.request.status === 403) {
                log.debug("403 Access Denied, redirecting user to /403-access-denied?path=${url}");

                if (requestOptions.bubble403) {
                    throw err;
                }

                location.href = `/403-access-denied?path=${url}`;
            }

            if (err.request.status === 404) {
                log.debug("404 Document not found");
                throw err;
            }

            if (err.request.status === 423) {
                log.debug("423 Access Denied, object can't be modified");
                return Promise.reject(err.request.status);
            }

            if (err.request.status === 503) {
                log.debug("503 Service Unavailable, enabling maintenance mode");
                store.dispatch(enableMaintenance());
            }
        }

        return Promise.reject(response);
    }
};

export const get = async <Type>(url: string, requestOptions: RequestOptions = {}): Promise<Type> =>
    request<Type>(RequestMethod.GET, url, requestOptions);
export const post = async <Type>(url: string, requestOptions: RequestOptions = {}): Promise<Type> =>
    request<Type>(RequestMethod.POST, url, requestOptions);
export const put = async <Type>(url: string, requestOptions: RequestOptions = {}): Promise<Type> =>
    request<Type>(RequestMethod.PUT, url, requestOptions);
export const delete_ = async <Type>(url: string, requestOptions: RequestOptions = {}): Promise<Type> =>
    request<Type>(RequestMethod.DELETE, url, requestOptions);
