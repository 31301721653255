import { createTheme, Theme } from "@mui/material";
import Vendor from "@src/features/customization/Vendor";
import { muiLocaleComponents } from "@src/i18n";

import DefaultTheme from "./DefaultTheme";

export const drawerWidth = 240;
export const closedDrawerWidth = 96 - 16 - 8;

const vendorTheme = (): Theme => {
    let theme: Theme;

    switch (CINNAMON_VENDOR) {
        case Vendor.Default:
        default:
            theme = DefaultTheme;
            break;
    }

    return createTheme(theme, ...muiLocaleComponents());
};

const theme = vendorTheme();

export default theme;
