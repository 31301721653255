import { CinnamonID } from "@src/api/types/CinnamonID";

import log from "./logger";
import { delete_, post } from "./request";

export enum Type {
    Company = "companies",
    Address = "addresses",
    Contact = "contacts",
    Debitor = "debitors",
    Document = "documents",
    ApiUsers = "apiUsers",
    Creditors = "creditors",
    Order = "orders",
    BusinessAreas = "businessAreas",
    ProductCategories = "productCategories",
    Product = "products",
    TextSnippet = "textSnippets",
    Job = "jobs",
    JobChannel = "jobChannels",
    JobMarket = "jobMarkets",
    Payments = "payments",
    Vats = "vats",
    Roles = "roles",
    Teams = "teams",
    Users = "users",
    ZTerms = "zterms",
}

export class Locks {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {}

    private static createLockTypePart(type: Type | Type[], id: CinnamonID | CinnamonID[]): string {
        if (!Array.isArray(type)) {
            type = [type];
        }

        if (!Array.isArray(id)) {
            id = [id];
        }

        if (type.length !== id.length) {
            log.fatal("Unmatched number of types and ids supplied", { id, type });
        }

        return type.map((t, index) => `${t}/${(id as CinnamonID[])[index]}`).join("/");
    }

    public static async acquire<T>(
        type: Type | Type[],
        id: CinnamonID | CinnamonID[],
        bubble403?: boolean,
    ): Promise<T> {
        const lock = await post<T>(`/internalApi/${Locks.createLockTypePart(type, id)}/lock`, { bubble403: bubble403 });

        log.debug("lock acquired", { lock });
        return lock;
    }

    public static async release(type: Type | Type[], id: CinnamonID | CinnamonID[]): Promise<null> {
        log.debug("lock released", { id, type });
        return await delete_(`/internalApi/${Locks.createLockTypePart(type, id)}/lock`);
    }
}
